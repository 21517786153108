/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import "~leaflet/dist/leaflet.css";

.noRadius {
  border-radius: 0 !important;
}

.slider {
  .ngx-slider {
    .ngx-slider-selection {
      background: #0c857a !important;
    }

    .ngx-slider-pointer-min {
      background: #0c857a !important;
    }

    .ngx-slider-pointer-max {
      background: #0c857a !important;
    }
  }
}

.mat-tab-label-container {
  padding: 0px !important;
}

.mat-tab-header-pagination {
  box-shadow: none !important;
}

.azul {
  color: #003097 !important;
}

.icon-2x {
  transform: scale(2);
}

.icon-3x {
  transform: scale(3);
}

.containerSwal {
  .box {
    margin: 1rem;
    display: flex;
    padding: 1rem;
    cursor: pointer;
    border-radius: 0.5rem;
    flex-direction: column;
    border: 0.1rem solid rgb(13, 148, 136);

    select {
      cursor: pointer;
    }
  }
}

.btnQuadrado {
  opacity: 0.9 !important;
  border-radius: 10px !important;
}

// mat-drawer {
//   min-width: 60% !important;
// }

// .leaflet-bottom.leaflet-right {
//   display: none !important;
// }

.mat-form-field-outline {
  background: white !important;
  border-radius: 0.5rem !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
